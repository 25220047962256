import { Upload, Row, Col, Card, Input, Select, Spin, Form } from 'antd';
import React, { useState, useEffect } from 'react';
import { getAllS3Bucket, getBucketFiles } from '../services/S3BucketService';
import GeoMap from './geomaps/GeoMap';
import { Responsive, WidthProvider } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { addWorkflow, emptyWorkflow, getWorkflow } from '../redux/workflow/actions';
import { connect } from 'react-redux';
import { useKeycloak } from '@react-keycloak/web';


function MapsComponent({  addWorkflow, emptyWorkflow,currentWorkflow }) {
  const [proceed, setProceed] = useState(false);
  //S3b buckets
  const [buckets, setBuckets] = useState([]);
  const [selectedBucketName, setSelectedBucketName] = useState("");
  const [bucketFiles, setBucketFiles] = useState([]);
  //Files Selectors
  const [selectedFileDataName, SetSelectedFileDataName] = useState("");
  const [isAnyBucketComponentLoading, setIsAnyBucketComponentLoading] = useState(true);
  useEffect(() => {
      setSelectedBucketName(currentWorkflow);
      LoadBucketFiles(currentWorkflow);
  }, [currentWorkflow])
  const { keycloak } = useKeycloak();

  const SpecialTips = ({ children }) => {
    return (
      <i style={{ fontSize: 10 }}>
        {children}
      </i>
    )
  }


  const isBucketSelected = () => {
    if (selectedBucketName.length === 0) {
      return false;
    }
    else {
      return true;
    }
  }

  const LoadBucketFiles = (name) => {
    setIsAnyBucketComponentLoading(true);
    getBucketFiles(keycloak.token,name)
      .then((resp) => {
        var items = resp;
        var files = [];
        for (let index = 0; index < items.length; index++) {
          var folderNames = items[index].name.split('/');
          var fileName = folderNames[folderNames.length - 1];
          if (fileName.toLowerCase().includes('.tif') || fileName.toLowerCase().includes('.tiff')) {
            files.push({
              label: items[index].name,
              value: items[index].presignedUrl
            });
          }
        }
        setBucketFiles(files);
        setIsAnyBucketComponentLoading(false);
      });
  }

  const onSelectBucket = (value) => {
    var bucketName = value;
    setSelectedBucketName(bucketName);

    LoadBucketFiles(bucketName);
  }

  const onchangeSelectedFileData = (value) => {
    SetSelectedFileDataName(value);
    setProceed(true);

  };
  const [form] = Form.useForm();

  return (
    <>

      <Spin tip="Loading Bucket Data ..." spinning={isAnyBucketComponentLoading}>
        <Form form={form}>
          <Card>
            <Row style={{ marginBottom: 20 }}>
              <Col span={14}>
                <Form.Item label="Bucket Name">
                <Input value={currentWorkflow} disabled={true} />
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ marginBottom: 30 }}>
              <Col span={7}>
                <Form.Item rules={[
                  {
                    message: 'Please select bucket file data',
                    min: 1
                  },
                ]} label="Data File" help={<SpecialTips>Only files with extensions '.tif', '.tiff' are allowed</SpecialTips>} required={true}>
                  <Select
                    showSearch
                    placeholder="Select Data file"
                    style={{ width: '100%' }}
                    optionFilterProp="children"
                    // hasFeedback
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    disabled={!isBucketSelected()}
                    options={bucketFiles}
                    onChange={onchangeSelectedFileData}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Form>
      </Spin>
      <br />
      {proceed && (
        <Card>
              <div className='grid-container'>
                <GeoMap tiffObject={selectedFileDataName} zoomSize={3}></GeoMap>        
                </div>
        </Card>
      )}
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    currentWorkflow: state.workflow.currentWorkflow,
  };
};

export default connect(mapStateToProps, { addWorkflow, emptyWorkflow,getWorkflow })(MapsComponent); 
