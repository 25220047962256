import React, { Component } from 'react';
//import Globe from 'worldwind-react-globe';
import MainLayout from '../layouts/MainLayout';
import CustomMapComponent from './map/CustomMapComponent';

class HomeView extends Component{

    render(){

        return (
            <MainLayout menu='1'>
                <div>
                    <CustomMapComponent/>
                </div>
            </MainLayout>
        )
    }
}

export default HomeView;