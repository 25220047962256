import React, { useContext, createContext } from 'react';
import { connect } from 'react-redux';
import { IncreaseQuantity, DecreaseQuantity, DeleteCart, EmptyCart } from '../redux/cart/actions';
import { Table, Tag, Button, Space } from 'antd';
import { Base64 } from 'js-base64';
import { showErrorMessage, showSuccessMessage, showWarningMessage } from '../common/utils';
import { createWorkflow } from '../services/workflowService';
import { useKeycloak } from '@react-keycloak/web';
import { KgWorkflowContext } from "../views/DashboardView.js";
import { v4 as uuidv4 } from 'uuid';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  generatePath,
  useLocation,
  useNavigate
} from "react-router-dom";
function Cart({ items, IncreaseQuantity, EmptyCart, DeleteCart }) {
  let ListCart = [];
  let TotalCart = 0;
  Object.keys(items.Carts).forEach(function (item) {
    TotalCart += items.Carts[item].quantity;
    ListCart.push(items.Carts[item]);
  });
  const { keycloak } = useKeycloak();
  const { count, tabId, pythonScript, metaJson, tabSet, reset, decrement, increment } = useContext(KgWorkflowContext);
  const useNavigateParams = () => {
    const navigate = useNavigate();

    return (url, params) => {
      const path = generatePath("/?tabKey=WE", {
        url,
        queryString: params
      });
      navigate(path);
    };
  };
  function TotalPrice(price) {
    return Number(price).toLocaleString('en-US');
  }
  const navigate = useNavigateParams();

  const createWF = () => {
    var values = ListCart;
    console.log(values);
    var workflowName = values.length>0 ? values[0].title+values[0].id: uuidv4();
    var queries = [];
    var meta = [];
    ListCart.forEach((item, index) => {
      queries.push(Base64.encode(item.pythonScript));
      meta.push(Array(JSON.parse(item.metaJSON)));
    });

    for (const key in values) {
      if (Object.hasOwnProperty.call(values, key)) {
        const element = values[key];

        if (key.includes("workflow_query-")) {
          queries.push(Base64.encode(element));
        }
        else if (key.includes("workflow_meta-")) {
          meta.push(JSON.parse(element));
        }
      }
    }
    var bodyContent = {};
    bodyContent = {
      name: workflowName ? workflowName : '',
      query: queries ? queries : '',
      meta: meta ? Base64.encode(JSON.stringify(meta.flat())) : ''
    }
    let data = JSON.stringify(bodyContent);
    if(values.length === 0) {
      showWarningMessage('You are about to create an empty workflow');

    setTimeout(() => {
  
    createWorkflow(keycloak.token, data)
      .then((response) => {
        showSuccessMessage(JSON.stringify(response));
        EmptyCart();
        // navigate("/", `tabKey=WE`);
      })
      .catch((error) => {
        showErrorMessage("Oups! Error " + JSON.stringify(error))
      })
    //showSuccessMessage("Real Sociedad!")
  }, 3000);
    } else {
      createWorkflow(keycloak.token, data)
      .then((response) => {
        showSuccessMessage(JSON.stringify(response));
        EmptyCart();
        // navigate("/", `tabKey=WE`);
      })
      .catch((error) => {
        showErrorMessage("Oups! Error " + JSON.stringify(error))
      })
    }
    //setIsModalOpen(false);
  };
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Name',
      dataIndex: 'title',
      key: 'title',
    },
    // {
    //   title: 'Quantity',
    //   dataIndex: 'quantity',
    //   key: 'quantity',
    //   render: (text, record) => (
    //     <Space>
    //       <Button onClick={() => DecreaseQuantity(record.key)}>-</Button>
    //       <span>{text}</span>
    //       <Button onClick={() => IncreaseQuantity(record.key)}>+</Button>
    //     </Space>
    //   ),
    // },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Button type="danger" onClick={() => DeleteCart(record.key)}>
          Delete
        </Button>
      ),
    },
  ];

  const data = ListCart.map((item, index) => ({
    ...item,
    key: index,
    totalPrice: TotalPrice(item.quantity),
  }));

  return (
    <div className="row">
      <div className="col-md-12">
        <Table
          columns={columns}
          dataSource={data}
          footer={() => (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>Total WF Products: {Number(TotalCart).toLocaleString('en-US')}</span>
              <Button type="primary" onClick={() => createWF()}>Create Workflow</Button>
             
            </div>
          )}
        />
      </div>
    </div>
  );
}


const mapStateToProps = (state) => {
  return {
    items: state.cart,
  };
};

export default connect(mapStateToProps, { IncreaseQuantity, DecreaseQuantity, DeleteCart, EmptyCart })(Cart);
