import React from 'react';
import { UserOutlined, BookOutlined, HomeOutlined,GlobalOutlined, HistoryOutlined, LogoutOutlined, CodeOutlined, DashboardOutlined, LineChartOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import { Col, Row } from 'antd';

import logo from './../logo.svg';
import { useKeycloak } from '@react-keycloak/web';

function getItem(label, key, icon, path, children, type) {
  return {
    key,
    icon,
    path,
    children,
    label,
    type
  };
}

const items = [
  { type: 'divider' },
  getItem('Home', '1', <DashboardOutlined />, '/home'),
  {
    type: 'divider',
  },
  // getItem('Home', '10', <DashboardOutlined />, '/home'),
  getItem('Data Visualization', '11', <LineChartOutlined />, '/analytics'),
  getItem('Map Visualization', '13', <GlobalOutlined />, '/map-visualization'), {
    type: 'divider',
  },
  getItem('Console', '20', <CodeOutlined />, '/console'),
  // getItem('Preferences', '2', <BookOutlined />, '/preferences'),
  {
    type: 'divider',
  },
  getItem('History', '3', <HistoryOutlined />, '/history'),
  getItem('Account', 'account', <UserOutlined />),
  getItem('User Guide', '19', <HistoryOutlined />, '/user-guides'),

  {
    type: 'divider',
  },
  getItem('Sign Out', 'logout', <LogoutOutlined />),
  {
    type: 'divider',
  }
].map((item, index) => {
  if (item.key === '4') {
    return {
      key: item.key,
      label: (<a href={item.path} target="_self" rel="noopener noreferrer">{item.label}</a>),
      icon: item.icon,
    };
  }
  if (item.key === 'account' || item.key === 'logout') {
    return item;
  }
  if (typeof item.type === 'undefined') {
    return {
      key: item.key,
      label: <Link to={item.path}>{item.label}</Link>,
      icon: item.icon
    };
  }
  else {
    return item;
  }
});

function SideBar(props) {

  const id = props.menu; //passing menu item key

  const { keycloak } = useKeycloak();
  const OnMenuItemClick = (e) => {

    if (e.key === 'account') {
      window.open(keycloak.createAccountUrl(), "_self")
    }

    if (e.key === 'logout') {
      window.open(keycloak.createLogoutUrl(), "_self")
    }

    /*if(e.key === 'console'){
      window.open(window.__RUNTIME_CONFIG__.REACT_APP_CONSOLE_URI, "_self")
    }*/

  }

  return (
    <div>
      <div
        style={{
          height: 160,
          padding: 20,
          borderBottomRightRadius: 10
        }}
      >
        <Row justify="space-around" align="middle">
          <Col span={20}>
            <img src={logo} alt="logo" style={{ mixBlendMode: 'plus-lighter' }} />
          </Col>
        </Row>
      </div>
      <Menu
        defaultSelectedKeys={[id]}
        mode="inline"
        theme='dark'
        items={items}
        onClick={(e) => { OnMenuItemClick(e) }}
      />
    </div>
  );
}

export default SideBar;
