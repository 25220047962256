import apiRequest from "../api/instance";

export const getStoreDataByRange = async (range = 100) =>{
    
    const config = {
        method: 'GET',
        url: '/Store/all?range=' + range
    };

    return await apiRequest(config);
}

export const deleteStoreData = async () =>{
    const config = {
        method: 'DELETE',
        url: '/Store/clear'
    };

    return await apiRequest(config);
}
