import { MapContainer, TileLayer, Polygon } from "react-leaflet"; //Marker, Popup,
import "leaflet/dist/leaflet.css";
import GeoTiffLayer from "./GeoTiffLayers";
import React, { useEffect, useState } from "react";

import "./GeoMap.css";


export default function GeoMap({ tiffObject, polygonCords,zoomSize }) {
  const options = {
    resolution: 255,
    opacity: 0.4,
  };
  const [polygon, setPolygon] = useState([]);
  const [center, setCenter] = useState([54.5260, 15.2551]);

  const [tiffLayer, setTiffLayer] = useState(false);
  function calculatePolygonCenter(coordinates) {
    // Separate latitude and longitude values
    const latitudes = coordinates.map(coord => coord[1]);
    const longitudes = coordinates.map(coord => coord[0]);
    // Calculate average latitude and longitude
    const avgLatitude = latitudes.reduce((sum, lat) => sum + lat, 0) / latitudes.length;
    const avgLongitude = longitudes.reduce((sum, lon) => sum + lon, 0) / longitudes.length;
    return [avgLongitude, avgLatitude];
  }
  useEffect(() => {
    if (tiffObject) {
      setTiffLayer(true);
    }
    if (polygonCords) {
      const validJsonString = polygonCords[0].replace(/'/g, '"');
      const resultArray = JSON.parse(validJsonString);      
      setCenter(calculatePolygonCenter(resultArray));
      setPolygon(resultArray.map((row) => [row[1], row[0]]));

    }
  }, [polygonCords,tiffObject]);

  return (<div style={{ width: 100 + "%", height: 100 + "vh" }}>


    <MapContainer style={{ width: "100%", height: "100%" }}
      bounds={polygon.length > 0 ? polygon : undefined}
      boundsOptions={polygon.length > 0 ? { padding: [1, 1] } : undefined}

      center={center} zoom={zoomSize} scrollWheelZoom={true}>
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">EO4EU</a> Consortium'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <>
        <TileLayer
          url='https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'
          maxZoom={20}
          subdomains={['mt1', 'mt2', 'mt3']}
        />
        {tiffLayer && (

          <GeoTiffLayer url={tiffObject} options={options} />
        )}
        {polygon && (

          <Polygon positions={polygon} />
        )}
      </>
    </MapContainer>
  </div>
  );
}
