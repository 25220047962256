import React, { useState, useEffect } from 'react';
import { AppstoreOutlined, MailOutlined, ShoppingCartOutlined, SettingOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import Header from './Header';
import { Link } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { getUserWorkflows } from '../services/dsl_DataService';
import { addWorkflow, emptyWorkflow, getWorkflow } from '../redux/workflow/actions';
import { connect } from 'react-redux';
import { connectSocket, authenticate, joinChannel, leaveChannel, receiveMessage,receiveELK } from '../redux/socket/actions';

function TopBar({  addWorkflow, emptyWorkflow,currentWorkflow,   messages,
  connectSocket,
  authenticate,
  joinChannel,
  leaveChannel
}){


  const [current, setCurrent] = useState('');
  const { keycloak } = useKeycloak();
  const [UserWorkflows, setUserWorkflows] = useState([])
  const [workflowList, setWorkflowList] = useState('');

  useEffect(() => {
    //implement logic here to get UserWorkflows

    getUserWorkflows(keycloak.token).then((response) => {
      setUserWorkflows(response);
    });

  }, [keycloak]);
  useEffect(() => {
    // Retrieve initial value of currentWorkflow
  }, [currentWorkflow]);
  useEffect(() => {
    connectSocket();
    authenticate(keycloak.token);
    // Add click event listener when component mounts
    return () => {
      if (currentWorkflow !== '') {
        leaveChannel(currentWorkflow);
      }

    };
  }, [connectSocket, authenticate, joinChannel, leaveChannel,currentWorkflow]);

  const onClick = (e) => {
    if (currentWorkflow !== '') {
      leaveChannel(currentWorkflow);
    }
    if (e.key !== 'products-wf' && e.key !== 'cart') {
      addWorkflow(e.key);
      joinChannel(e.key);
    }
    setCurrent(e.key);
  };
  const items = [
    {
      label: <Header />,
      key: 'cart',
    },
    {
      label: <Link to="/products-wf" className="nav-link">My WF Products</Link>,
      key: 'products-wf',
      icon: <AppstoreOutlined />,
    },
    {
      label: (currentWorkflow) ? currentWorkflow : 'Select Workflow',
      key: 'SubMenu',
      icon: <SettingOutlined />,
      children:UserWorkflows.length > 0? UserWorkflows.map(item => ({
        label: item.id,
        key:  item.id
      })) : [{ label: "No workflows",item: "",}],

    },
  ];
  return (<Menu onClick={onClick} selectedKeys={[current]} mode="horizontal" items={items} />);
}
const mapStateToProps = (state) => {
  return {
    currentWorkflow: state.workflow.currentWorkflow,
    messagesKafka: state.socketData.messagesKafka,
    messagesElk: state.socketData.messagesElk,
    messagesInit: state.socketData.messagesInit,

  };
};

export default connect(mapStateToProps, { addWorkflow, emptyWorkflow,getWorkflow,receiveELK,  connectSocket,
  authenticate,
  joinChannel,
  leaveChannel,
  receiveMessage })(TopBar);
