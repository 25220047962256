import React from 'react';
import { Routes, Route } from 'react-router';
import PrivateComponent from '../components/PrivateComponent';
import HomeView from "../views/HomeView";
import HistoryView from "../views/HistoryView";
import PreferencesView from "../views/PreferencesView";
import DashboardView from '../views/DashboardView';
import ConsoleView from '../views/ConsoleView';
import AnalyticsView from '../views/AnalyticsView';
import MapsView from '../views/MapsView';
import ProductWF from '../components/ProductWF';
import Cart from '../components/Cart';
import ProductsView from '../views/ProductsView';
import CartView from '../views/CartView';
import WikiView from '../views/WikiView';
function MyRoutes() {
    return (
        <Routes>
            <Route path="/"
                element={
                    <PrivateComponent>
                        <DashboardView />
                    </PrivateComponent>
                }
            />
            {/* <Route path="/home" 
            element={
                <PrivateComponent>
                <HomeView />
                </PrivateComponent>
            } 
            /> */}
            <Route path="/home"
                element={
                    <PrivateComponent>
                        <DashboardView />
                    </PrivateComponent>
                }
            />
            <Route path="/user-guides"
                element={
                    <PrivateComponent>
                        <WikiView />
                    </PrivateComponent>
                }
            />
            <Route path="/analytics"
                element={
                    <PrivateComponent>
                        <AnalyticsView />
                    </PrivateComponent>
                }
            />
            <Route path="/map-visualization"
                element={
                    <PrivateComponent>
                        <MapsView />
                    </PrivateComponent>
                }
            />
            <Route path="/cart"
                element={
                    <PrivateComponent>
                        <CartView />
                    </PrivateComponent>
                }
            />
            <Route path="/products-wf"
                element={
                    <PrivateComponent>
                        <ProductsView />
                    </PrivateComponent>
                }
            />

            <Route path="/history"
                element={
                    <PrivateComponent>
                        <HistoryView />
                    </PrivateComponent>
                }
            />
            <Route path="/console"
                element={
                    <PrivateComponent>
                        <ConsoleView />
                    </PrivateComponent>
                }
            />
            <Route path="/preferences"
                element={
                    <PrivateComponent>
                        <PreferencesView />
                    </PrivateComponent>
                }
            />
        </Routes>
    );
}

export default MyRoutes