import React from "react";
/* import Bookmarks from '@arcgis/core/widgets/Bookmarks';
import Expand from '@arcgis/core/widgets/Expand';
import SceneView from "@arcgis/core/views/SceneView";
import Map from "@arcgis/core/Map";
import Graphic from "@arcgis/core/Graphic";
import {whenOnce} from "@arcgis/core/core/reactiveUtils";
import Mesh from "@arcgis/core/geometry/Mesh";
import Point from "@arcgis/core/geometry/Point";
import esriConfig from "@arcgis/core/config"; */

import "./CustomMapComponent.css";

export default function CustomMapComponent() {

 /*  esriConfig.apiKey = "AAPKc2bf5b830714431499088509b8923952LJh39g6AMVUr1rhtqoGG_gnFKt9RTNQL";

  const mapDiv = useRef(null);

  const R = 6358137 // approximate radius of the Earth in m
  const offset = 300000 // offset from the ground used for the clouds

  useEffect(() => {
    if (mapDiv.current) {
      const map = new Map({
        portalItem: {
          id: "aa1d3f80270146208328cf66d022e09c"
        },
        ground: "world-elevation",
        basemap: "arcgis-imagery"
      });

      const view = new SceneView({
        container: mapDiv.current,
          map: map,
          alphaCompositingEnabled: true,
          qualityProfile: "high",
          camera: {
            position: [-55.03975781, 14.94826384, 19921223.30821],
            heading: 2.03,
            tilt: 0.13,
          },
          environment: {
            starsEnabled: true,
            atmosphereEnabled: true,
            lighting: {
              directShadowsEnabled: true,
              date:
                "Sun Jun 23 2019 19:19:18 GMT+0200 (Central European Summer Time)",
            },
          },
          constraints: {
            altitude: {
              min: 10000000,
              max: 25000000,
            },
          },
          popup: {
            dockEnabled: true,
            dockOptions: {
              position: "top-right",
              breakpoint: false,
              buttonEnabled: false,
            },
            collapseEnabled: false,
          },
          highlightOptions: {
            color: [255, 255, 255],
            haloOpacity: 0.5,
          },
      });

      const bookmarks = new Bookmarks({
        view,
        // allows bookmarks to be added, edited, or deleted
        editingEnabled: true
      });

      const bkExpand = new Expand({
        view,
        content: bookmarks,
        expanded: true
      });

      /* const cloudsSphere = Mesh.createSphere(
        new Point({
          x: 0,
          y: -90,
          z: -(2 * R + offset)
        }),
        {
          size: 2 * (R + offset),
          material: {
            colorTexture: "https://raw.githubusercontent.com/RalucaNicola/the-globe-of-extremes/master/clouds-nasa.png",
            doubleSided: false
          },
          densificationFactor: 5
        }
      ); 

      cloudsSphere.components[0].shading = "flat"; */

      /*const clouds = new Graphic({
        //geometry: cloudsSphere,
        symbol: {
          type: "mesh-3d",
          symbolLayers: [{ type: "fill" }]
        }
      });

      view.graphics.add(clouds);

        let isPlaying = true;

        view.when(function() {
            whenOnce(view, "updating", rotate);
        });

        function rotate() {
            if (isPlaying) {
            const camera = view.camera.clone()
            camera.position.longitude -= 0.2
            view.goTo(camera, { animate: true })
            requestAnimationFrame(rotate)
            }
        }

      // Add the widget to the top-right corner of the view
      view.ui.add(bkExpand, "top-right");

      rotate();
    }
  }, []); */

  return <div className="mapDiv" ></div>;
}